<template>
  <section class="grouped">
    <div v-if="venue.contacts.length == 0" class="elements">
      No hay nada añadido
    </div>
    <div v-else class="elements">
      <b-row class="font-weight-bold">
        <b-col cols="4">
          <span>
            Nombre
          </span>
        </b-col>
        <b-col cols="4">
          <span>
            Cargo
          </span>
        </b-col>
        <b-col cols="4">
          <span>
            Email
          </span>
        </b-col>
      </b-row>
      <b-row v-for="element in venue.contacts" :key="element.id">
        <b-col cols="4">
          <span>
            {{ element.name }}
          </span>
        </b-col>
        <b-col cols="4">
          <span>
            {{ element.position }}
          </span>
        </b-col>
        <b-col cols="4">
          <span>
            {{ element.email }}
          </span>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ListVenueContact',
  computed: {
    ...mapGetters({ venue: 'venues/current' })
  }
}
</script>

<style lang="less" scoped>
@import "listVenueContact";
</style>
