<template>
  <ProjectLayout
    :displayedCard="'detail-venue'"
    v-if="venue"
  >
    <b-card
      title="Venue del proyecto"
      align="left"
      class="user-create-card"
    >
      <b-container>
        <b-row>
          <b-col>
            <h6>Información básica</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span class="venue-name">
              {{ venue.name }}
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Direcciones</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ListVenueAddress/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6>Contactos</h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <ListVenueContact/>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </ProjectLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'
import ListVenueAddress from '@/components/Business/Project/Venue/Address/ListVenueAddress.vue'
import ListVenueContact from '@/components/Business/Project/Venue/Contact/ListVenueContact.vue'

export default {
  name: 'DetailVenue',
  components: {
    ProjectLayout,
    ListVenueAddress,
    ListVenueContact
  },
  created () {
    this.getVenueById(this.$route.params.venueId)
  },
  computed: {
    ...mapGetters({ venue: 'venues/current' })
  },
  methods: {
    ...mapActions('venues', { getVenueById: 'getOneById' })
  }
}
</script>

<style lang="less" scoped>
@import "detailVenue";
</style>
